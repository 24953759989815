import React from 'react';
import BgImageNerd from '../../images/Pics/pic_home_nerds.svg';

/*---CVT---*/
import { CvtColorSchema } from '@conventic-web/internal.global';
import { BlockTop } from '@conventic-web/internal.cvt.layout.block-top';
import { BlockStandard } from '@conventic-web/internal.cvt.layout.block-standard';

/*---Components---*/
import Layout from '../../components/skeleton_page/layout';
import JobOfferSingle from './Karriere_comps/JobOfferSingle';

import { PaddingObj, DiagonalObj } from '../../global/sharedObjects';

const JobOfferPage = () => {
  return (
    <Layout>
      {/* <Seo
        title={frontmatter.pageTitle}
        keywords={frontmatter.seoKeywords}
        description={frontmatter.pageDescription}
        image={featuredImage}
        pathname={location.pathname}
      /> */}
      <BlockTop
        headline={'KARRIERE bei CONVENTIC'}
        subheadline={'Dein Job...'}
        diagonalAlignment={DiagonalObj.DiagonalLeftBelow as any}
        bgImage={BgImageNerd}
        zIndex={8}
        padding={PaddingObj.PaddingStarter}
        color={CvtColorSchema.white}
      />
      <BlockStandard
        diagonalAlignment={DiagonalObj.DiagonalLeftBelow as any}
        bgColor={CvtColorSchema.white}
        zIndex={7}
        padding={PaddingObj.PaddingIdle}
      >
        <JobOfferSingle />
      </BlockStandard>
    </Layout>
  );
};

export default JobOfferPage;
