import React from 'react';
import styled from '@emotion/styled';

/*---CVT---*/
import { Headline } from '@conventic-web/internal.cvt.data-display.headline';
import { CvtColorSchema } from '@conventic-web/internal.global';
import { Alert } from '@conventic-web/internal.basic.alert';

/*---Material---*/
import AddIcon from '@mui/icons-material/Add';
import { Box, CircularProgress } from '@mui/material';

/*---Components---*/
import { LinkComponent } from '../../../components/linkComponent';

/*---Global---*/
import { getJobOfferFromPersonio } from '../../../global/functions';
import SYSTEM_STRINGS from '../../../utility/data/system/systemStrings.yaml';
import { CssEffectObj, SystemObj } from '../../../global/sharedObjects';

const NoExtendWrapper = styled.div`
  width: 100%;
  background-color: ${CvtColorSchema.green};
  color: ${CvtColorSchema.white};
  background-image: linear-gradient(
    ${CvtColorSchema.green},
    ${CvtColorSchema.green}
  );
  ${CssEffectObj.skew}
  ${CssEffectObj.hoverEffekt}
  &:hover {
    background-image: linear-gradient(
      ${CvtColorSchema.yellow},
      ${CvtColorSchema.yellow}
    );
  }
`;

const NoExtendWrapperUnskew = styled.div`
  ${CssEffectObj.unskew}
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

const JobOfferOverview = () => {
  const { isLoading, error, data } = getJobOfferFromPersonio();
  const styles = {
    root: {
      marginTop: '1em',
      display: 'flex',
      flexDirection: 'column',
    },

    containerBtnTitle: {
      fontWeight: 600,
      padding: '1.5em',
      cursor: 'pointer',
    },
    containerIcons: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: '0.5em',
    },
  } as const;

  const displayJobDesciptions = (jobArr: any) => {
    return (
      <>
        {jobArr.map((ele: any) => {
          return (
            <Box component="div" sx={styles.root} key={ele.id[0]}>
              <LinkComponent
                linkTo={SYSTEM_STRINGS.LINKS.INTERN.KARRIERE + '/' + ele.id[0]}
              >
                <NoExtendWrapper>
                  <NoExtendWrapperUnskew>
                    {ele.name[0] && (
                      <Box
                        component="div"
                        sx={styles.containerBtnTitle}
                        id={ele.id[0]}
                      >
                        {ele.name[0]}
                      </Box>
                    )}
                    <Box component="div" sx={styles.containerIcons}>
                      <AddIcon style={{ fontSize: 60 }} />
                    </Box>
                  </NoExtendWrapperUnskew>
                </NoExtendWrapper>
              </LinkComponent>
            </Box>
          );
        })}
      </>
    );
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center">
        <Alert
          severity={SystemObj.SEVERITY.ERROR as any}
          text={SYSTEM_STRINGS.ERROR_MESSAGES.LOADING}
          variant="filled"
        />
      </Box>
    );
  }

  const jobOffers = data['workzag-jobs']['position'];
  return (
    <>
      {jobOffers.length > 0 && (
        <>
          <Headline variant="h2" text="Jobangebote" />
          {displayJobDesciptions(jobOffers)}
        </>
      )}
    </>
  );
};

export default JobOfferOverview;
