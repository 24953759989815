import React from 'react';

/*---Components---*/
import PageblockPhotoHalf from './pageBlockPhotoHalf';

/*---CVT---*/
import { CvtColorSchema, CvtBasicTheme } from '@conventic-web/internal.global';
import { HtmlContent } from '@conventic-web/internal.basic.html-content';
import MarkdownIt from 'markdown-it';
const md = MarkdownIt({ html: true });

const UserStories = (data: any, zIndex: number) => {
  const returnArr: any[] = [];
  let z_index = zIndex;

  data.forEach((item: any, index: number) => {
    returnArr.push(
      <CardPhotoHalf
        indexZ={z_index}
        headline={item.headline}
        image={item.image}
        body={md.render(item.content)}
        orderPositioning={index}
        key={'userstory' + index}
      />
    );
    z_index--;
  });
  return returnArr;
};

export default UserStories;

const CardPhotoHalf = ({
  indexZ,
  headline,
  image,
  body,
  orderPositioning,
}: {
  indexZ: number;
  headline: string;
  image: any;
  body: string;
  orderPositioning: number;
}) => {
  //Filtering Position and Coloring - even and odd
  let color;
  let imgPosition;
  if (orderPositioning % 2 === 0) {
    color = CvtColorSchema.bgGray;
    imgPosition = 'left';
  } else {
    color = CvtColorSchema.white;
    imgPosition = 'right';
  }

  return (
    <PageblockPhotoHalf
      headline={headline}
      bgcolor={color}
      indexZ={indexZ}
      imgposition={imgPosition}
      image={
        !image.childImageSharp && image.extension === 'jpg'
          ? image.publicURL
          : image.publicURL
      }
    >
      <HtmlContent
        dangerouslySetInnerHTML={body}
        style={CvtBasicTheme.htmlContent.general}
      />
    </PageblockPhotoHalf>
  );
};
