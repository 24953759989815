import React from 'react';

import { Router } from '@reach/router';
import JobOfferPage from '../../templates/Karriere-page/JobOffer-page';
import JobApplicationPage from '../../templates/Karriere-page/JobApplication-page';
import { KarriereMainPage } from '../../templates/Karriere-page/Karriere-page';

const KarrierePage: React.FC = () => {
  return (
    <Router>
      <KarriereMainPage path="/Karriere/" />
      <JobOfferPage path="/Karriere/:id" />
      <JobApplicationPage path="/Karriere/Bewerbung/:id" />
    </Router>
  );
};

export default KarrierePage;
