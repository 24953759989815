import React from 'react';
import isEmail from 'validator/lib/isEmail';
import {
  Control,
  Controller,
  DeepMap,
  FieldError,
  FieldValues,
} from 'react-hook-form';

/*---CVT---*/
import { Headline } from '@conventic-web/internal.cvt.data-display.headline';
import { CvtColorSchema, CvtBasicTheme } from '@conventic-web/internal.global';
import { HtmlContent } from '@conventic-web/internal.basic.html-content';

/*---Utilities---*/
import SYSTEM_STRINGS from '../../../utility/data/system/systemStrings.yaml';

/*---Material---*/
import {
  TextField,
  Checkbox,
  Grid,
  FormControl,
  FormHelperText,
} from '@mui/material';

/*---Components---*/
import { DragAndDropArea } from './DragAndDropArea';

/*---Global---*/
import { FormMetaData } from '../../global/types';

import MarkdownIt from 'markdown-it';
const md = MarkdownIt({ html: true });

const JobApplicationForm = ({
  formMetaData,
  control,
  errors,
  getValues,
  handleCvFiles,
  cvFiles,
  handleOtherFiles,
  otherFiles,
  handleRejectedCvFiles,
  cvDataError,
  handleRejectedOtherFiles,
  otherDataError,
}: {
  formMetaData: FormMetaData[];
  control: Control<FieldValues>;
  errors: DeepMap<FieldValues, FieldError>;
  getValues: any;
  handleCvFiles: any;
  cvFiles: any;
  handleOtherFiles: any;
  otherFiles: any;
  handleRejectedCvFiles: any;
  cvDataError: any;
  handleRejectedOtherFiles: any;
  otherDataError: any;
}) => {
  const getFieldComponent = (data: FormMetaData) => {
    let retVal = null;
    switch (data.type) {
      case 'text':
        retVal = (
          <Controller
            control={control}
            defaultValue={''}
            name={data.name}
            rules={{ required: data.required }}
            render={({ field: { onChange, onBlur, value } }) => (
              <TextField
                required={data.required}
                error={data.id in errors ? true : false}
                helperText={
                  data.id in errors
                    ? SYSTEM_STRINGS.COMPONENTS.JOB_APPLICATION.ERROR.ALLGEMEIN
                    : null
                }
                fullWidth
                id={data.id}
                label={data.label}
                variant="standard"
                onBlur={onBlur}
                value={value}
                onChange={(val) => onChange(val)}
                inputProps={{ style: { color: CvtColorSchema.fontDarkGray } }}
              />
            )}
          />
        );
        break;
      case 'email':
        retVal = (
          <Controller
            control={control}
            defaultValue={''}
            name={data.name}
            rules={{
              required: true,
              validate: () => {
                return isEmail(getValues(data.name));
              },
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <TextField
                required={data.required}
                error={data.id in errors ? true : false}
                helperText={
                  data.id in errors
                    ? SYSTEM_STRINGS.COMPONENTS.JOB_APPLICATION.ERROR.ALLGEMEIN
                    : null
                }
                fullWidth
                id={data.id}
                label={data.label}
                variant="standard"
                onBlur={onBlur}
                value={value}
                onChange={(val) => onChange(val)}
                inputProps={{ style: { color: CvtColorSchema.fontDarkGray } }}
              />
            )}
          />
        );
        break;
      case 'date':
        retVal = (
          <Controller
            control={control}
            defaultValue={new Date()}
            name={data.name}
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value } }) => (
              <div>nothing here</div>
            )}
          />
        );
        break;
      case 'checkbox':
        retVal = (
          <Controller
            control={control}
            defaultValue={false}
            name={data.name}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <FormControl required error={data.id in errors ? true : false}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Grid item>
                    <Checkbox
                      required
                      checked={value}
                      name="checkbox"
                      onChange={(val) => onChange(val)}
                      color="primary"
                    />
                  </Grid>
                  <Grid item>
                    <HtmlContent
                      dangerouslySetInnerHTML={md.render(
                        SYSTEM_STRINGS.COMPONENTS.JOB_APPLICATION.GENERAL
                          .PRIVACY_POLICY
                      )}
                      style={CvtBasicTheme.htmlContent.newsletter}
                    />
                  </Grid>
                  {data.id in errors ? (
                    <FormHelperText>
                      {
                        SYSTEM_STRINGS.COMPONENTS.JOB_APPLICATION.ERROR
                          .ALLGEMEIN
                      }
                    </FormHelperText>
                  ) : null}
                </Grid>
              </FormControl>
            )}
          />
        );
        break;
      default:
        retVal = null;
    }

    return (
      <Grid item xs={data.gridSize}>
        {retVal}
      </Grid>
    );
  };

  return (
    <form>
      <Grid container spacing={3}>
        {formMetaData.map((formItem: FormMetaData, index: number) => {
          return (
            <React.Fragment key={'formField-' + index}>
              {getFieldComponent(formItem, index)}
            </React.Fragment>
          );
        })}

        <Grid item xs={12}>
          <Headline
            text={SYSTEM_STRINGS.COMPONENTS.JOB_APPLICATION.LABEL.DOCUMENTS}
            variant={'h3'}
          />
        </Grid>
        <Grid item xs={6}>
          <h4>{SYSTEM_STRINGS.COMPONENTS.JOB_APPLICATION.LABEL.CV}</h4>
          <DragAndDropArea
            handleFiles={handleCvFiles}
            files={cvFiles}
            handleRejectedFiles={handleRejectedCvFiles}
            dataError={cvDataError}
          />
        </Grid>
        <Grid item xs={6}>
          <h4>{SYSTEM_STRINGS.COMPONENTS.JOB_APPLICATION.LABEL.OTHERS}</h4>
          <DragAndDropArea
            handleFiles={handleOtherFiles}
            files={otherFiles}
            handleRejectedFiles={handleRejectedOtherFiles}
            dataError={otherDataError}
          />
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        ></Grid>
      </Grid>
    </form>
  );
};

const Wrapper_JobApplicationForm = ({
  formMetaData,
  control,
  errors,
  getValues,
  handleCvFiles,
  cvFiles,
  handleOtherFiles,
  otherFiles,
  handleRejectedCvFiles,
  cvDataError,
  handleRejectedOtherFiles,
  otherDataError,
}: {
  formMetaData: FormMetaData[];
  control: Control<FieldValues>;
  errors: DeepMap<FieldValues, FieldError>;
  getValues: any;
  handleCvFiles: any;
  cvFiles: any;
  handleOtherFiles: any;
  otherFiles: any;
  handleRejectedCvFiles: any;
  cvDataError: any;
  handleRejectedOtherFiles: any;
  otherDataError: any;
}) => {
  return (
    <JobApplicationForm
      formMetaData={formMetaData}
      control={control}
      getValues={getValues}
      errors={errors}
      handleCvFiles={handleCvFiles}
      cvFiles={cvFiles}
      handleOtherFiles={handleOtherFiles}
      otherFiles={otherFiles}
      handleRejectedCvFiles={handleRejectedCvFiles}
      cvDataError={cvDataError}
      handleRejectedOtherFiles={handleRejectedOtherFiles}
      otherDataError={otherDataError}
    />
  );
};

export default Wrapper_JobApplicationForm;
