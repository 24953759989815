import React, { useState, useEffect } from 'react';
import { QueryClient, QueryClientProvider, useMutation } from 'react-query';
import { useLocation } from '@reach/router';
import BgImageNerd from '../../images/Pics/pic_home_nerds.svg';
import { FieldValues, useForm } from 'react-hook-form';
import { format } from 'date-fns';

/*---Components---*/
import Layout from '../../components/skeleton_page/layout';
import Seo from '../../components/skeleton_page/seo';
import JobApplicationForm from './Karriere_comps/JobApplicationForm';

/*---CVT---*/
import { CvtColorSchema } from '@conventic-web/internal.global';
import { Button } from '@conventic-web/internal.cvt.basic.button';
import { Headline } from '@conventic-web/internal.cvt.data-display.headline';
import { BlockTop } from '@conventic-web/internal.cvt.layout.block-top';
import { BlockStandard } from '@conventic-web/internal.cvt.layout.block-standard';
import { Alert } from '@conventic-web/internal.basic.alert';

/*---Material---*/
import { CircularProgress, Box, Grid } from '@mui/material';

/*---Global---*/
import { FormMetaData } from '../../global/types';
import SYSTEM_STRINGS from '../../utility/data/system/systemStrings.yaml';
import { getJobOfferFromPersonio } from '../../global/functions';
import {
  PaddingObj,
  DiagonalObj,
  SystemObj,
} from '../../global/sharedObjects';

type ErrorStatus = {
  state: boolean;
  code: string;
  message: string;
};

type CustomFile = {
  categorised_documents_category: string;
  categorised_documents_file: any;
  filename: string;
};

type ApplicationObject = {
  job_position_id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  location: string;
  salary_expectations: string;
  available_from: string;
  custom_attribute_260278?: string;
  custom_attribute_213825?: string;
  custom_attribute_213814?: string;
  custom_attribute_213813?: string;
  custom_attribute_806426: string;
  cvFiles: CustomFile[];
  otherFiles: CustomFile[];
};

const JobApplicationPage = () => {
  const location = useLocation();
  const jobId = location.pathname.split('/').pop();
  const { isLoading, error, data } = getJobOfferFromPersonio();

  const {
    control,
    handleSubmit,
    formState,
    getValues,
    reset: resetForm,
  } = useForm();
  const [cvFiles, setCvFiles] = useState<any[]>([]);
  const [otherFiles, setOtherFiles] = useState<any[]>([]);
  const [submittedData, setSubmittedData] = useState<FieldValues>({});

  const errorInit: ErrorStatus = {
    state: false,
    code: '',
    message: '',
  };

  const [showAlert, setShowAlert] = useState<ErrorStatus>(errorInit);
  const [cvDataError, setCvDataError] = useState<ErrorStatus>(errorInit);
  const [otherDataError, setOtherDataError] = useState<ErrorStatus>(errorInit);
  const formMetaData: FormMetaData[] = [
    {
      type: 'text',
      required: true,
      gridSize: 6,
      id: SYSTEM_STRINGS.COMPONENTS.JOB_APPLICATION.ID.FIRSTNAME,
      name: SYSTEM_STRINGS.COMPONENTS.JOB_APPLICATION.ID.FIRSTNAME,
      label: SYSTEM_STRINGS.COMPONENTS.JOB_APPLICATION.LABEL.FIRSTNAME,
    },
    {
      type: 'text',
      required: true,
      gridSize: 6,
      id: SYSTEM_STRINGS.COMPONENTS.JOB_APPLICATION.ID.SURNAME,
      name: SYSTEM_STRINGS.COMPONENTS.JOB_APPLICATION.ID.SURNAME,
      label: SYSTEM_STRINGS.COMPONENTS.JOB_APPLICATION.LABEL.SURNAME,
    },
    {
      type: 'email',
      required: true,
      gridSize: 6,
      id: SYSTEM_STRINGS.COMPONENTS.JOB_APPLICATION.ID.EMAIL,
      name: SYSTEM_STRINGS.COMPONENTS.JOB_APPLICATION.ID.EMAIL,
      label: SYSTEM_STRINGS.COMPONENTS.JOB_APPLICATION.LABEL.EMAIL,
    },
    {
      type: 'text',
      required: true,
      gridSize: 6,
      id: SYSTEM_STRINGS.COMPONENTS.JOB_APPLICATION.ID.PHONE,
      name: SYSTEM_STRINGS.COMPONENTS.JOB_APPLICATION.ID.PHONE,
      label: SYSTEM_STRINGS.COMPONENTS.JOB_APPLICATION.LABEL.PHONE,
    },
    {
      type: 'text',
      required: true,
      gridSize: 6,
      id: SYSTEM_STRINGS.COMPONENTS.JOB_APPLICATION.ID.SALARY_EXPECTATIONS,
      name: SYSTEM_STRINGS.COMPONENTS.JOB_APPLICATION.ID.SALARY_EXPECTATIONS,
      label:
        SYSTEM_STRINGS.COMPONENTS.JOB_APPLICATION.LABEL.SALARY_EXPECTATIONS,
    },
    {
      type: 'text',
      required: true,
      gridSize: 6,
      id: SYSTEM_STRINGS.COMPONENTS.JOB_APPLICATION.ID.LOCATION,
      name: SYSTEM_STRINGS.COMPONENTS.JOB_APPLICATION.ID.LOCATION,
      label: SYSTEM_STRINGS.COMPONENTS.JOB_APPLICATION.LABEL.LOCATION,
    },
    {
      type: 'date',
      required: true,
      gridSize: 12,
      id: SYSTEM_STRINGS.COMPONENTS.JOB_APPLICATION.ID.AVAILABLE,
      name: SYSTEM_STRINGS.COMPONENTS.JOB_APPLICATION.ID.AVAILABLE,
      label: SYSTEM_STRINGS.COMPONENTS.JOB_APPLICATION.LABEL.AVAILABLE,
    },

    {
      type: 'text',
      required: false,
      gridSize: 6,
      id: SYSTEM_STRINGS.COMPONENTS.JOB_APPLICATION.ID.GOT_TO_KNOW_US,
      name: SYSTEM_STRINGS.COMPONENTS.JOB_APPLICATION.ID.GOT_TO_KNOW_US,
      label: SYSTEM_STRINGS.COMPONENTS.JOB_APPLICATION.LABEL.GOT_TO_KNOW_US,
    },
    {
      type: 'text',
      required: false,
      gridSize: 6,
      id: SYSTEM_STRINGS.COMPONENTS.JOB_APPLICATION.ID.GITHUB,
      name: SYSTEM_STRINGS.COMPONENTS.JOB_APPLICATION.ID.GITHUB,
      label: SYSTEM_STRINGS.COMPONENTS.JOB_APPLICATION.LABEL.GITHUB,
    },
    {
      type: 'text',
      required: false,
      gridSize: 6,
      id: SYSTEM_STRINGS.COMPONENTS.JOB_APPLICATION.ID.XING,
      name: SYSTEM_STRINGS.COMPONENTS.JOB_APPLICATION.ID.XING,
      label: SYSTEM_STRINGS.COMPONENTS.JOB_APPLICATION.LABEL.XING,
    },
    {
      type: 'text',
      required: false,
      gridSize: 6,
      id: SYSTEM_STRINGS.COMPONENTS.JOB_APPLICATION.ID.LINKED_IN,
      name: SYSTEM_STRINGS.COMPONENTS.JOB_APPLICATION.ID.LINKED_IN,
      label: SYSTEM_STRINGS.COMPONENTS.JOB_APPLICATION.LABEL.LINKED_IN,
    },
    {
      type: 'checkbox',
      required: true,
      gridSize: 12,
      id: SYSTEM_STRINGS.COMPONENTS.JOB_APPLICATION.ID.PRIVACY_POLICY,
      name: SYSTEM_STRINGS.COMPONENTS.JOB_APPLICATION.ID.PRIVACY_POLICY,
      label: SYSTEM_STRINGS.COMPONENTS.JOB_APPLICATION.ID.PRIVACY_POLICY,
    },
  ];

  const url = location.origin + SYSTEM_STRINGS.LINKS.NF_FUNCTIONS.PERSONIO;

  //https://codesandbox.io/s/convert-file-to-base64-in-react-lqi1e?file=/src/App.js
  const getBase64 = async (file: File) => {
    const promise = new Promise((resolve) => {
      let baseURL = '';
      // Make new FileReader
      const reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result as string;
        resolve(baseURL);
      };
    });

    return await promise;
  };

  const handleApplicationFiles = async (files: any[], category: string) => {
    const retArr = [];
    for (const file of files) {
      retArr.push({
        categorised_documents_category: category,
        categorised_documents_file: await getBase64(file),
        filename: file.name,
      });
    }
    return retArr;
  };

  const mutationForm = useMutation(async () => {
    const applicationObject: ApplicationObject = {
      job_position_id: jobId as string,
      first_name: submittedData.first_name,
      last_name: submittedData.last_name,
      email: submittedData.email,
      phone: submittedData.phone,
      location: submittedData.location,
      salary_expectations: submittedData.salary_expectations,
      available_from: format(submittedData.available_from, 'dd.MM.yyyy'),
      custom_attribute_806426: submittedData.custom_attribute_806426
        ? 'zugestimmt'
        : 'abgelehnt',
      cvFiles: [],
      otherFiles: [],
    };

    //not required items
    const notRequiredInputFieldKeys = [
      SYSTEM_STRINGS.COMPONENTS.JOB_APPLICATION.ID.GOT_TO_KNOW_US,
      SYSTEM_STRINGS.COMPONENTS.JOB_APPLICATION.ID.XING,
      SYSTEM_STRINGS.COMPONENTS.JOB_APPLICATION.ID.GITHUB,
      SYSTEM_STRINGS.COMPONENTS.JOB_APPLICATION.ID.LINKED_IN,
    ];

    for (const key of notRequiredInputFieldKeys) {
      if (submittedData[key] !== '') {
        applicationObject[key] = submittedData[key];
      }
    }

    //CV
    applicationObject.cvFiles = await handleApplicationFiles(cvFiles, 'cv');

    //Others
    applicationObject.otherFiles = await handleApplicationFiles(
      otherFiles,
      'other'
    );

    return fetch(url, {
      method: 'POST',
      body: JSON.stringify(applicationObject),
    });
  });

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (showAlert.state) {
      timer = setTimeout(() => {
        setShowAlert({
          ...showAlert,
          ...errorInit,
        });
      }, 4000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [showAlert]);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (cvDataError.state) {
      timer = setTimeout(() => {
        setCvDataError({
          ...cvDataError,
          ...errorInit,
        });
      }, 2000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [cvDataError]);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (otherDataError.state) {
      timer = setTimeout(() => {
        setOtherDataError({
          ...otherDataError,
          ...errorInit,
        });
      }, 2000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [otherDataError]);

  const handleCvFiles = (acceptedFiles: any) => {
    setCvFiles(acceptedFiles);
  };

  const handleOtherFiles = (acceptedFiles: any) => {
    setOtherFiles(acceptedFiles);
  };

  const handleRejectedCvFiles = (rejectedFiles: any) => {
    setCvDataError({
      ...cvDataError,
      state: true,
      code: rejectedFiles[0].errors[0].code,
      message: rejectedFiles[0].errors[0].message,
    });
  };

  const handleRejectedOtherFiles = (rejectedFiles: any) => {
    setOtherDataError({
      ...otherDataError,
      state: true,
      code: rejectedFiles[0].errors[0].code,
      message: rejectedFiles[0].errors[0].message,
    });
  };

  const onSubmit = (data: FieldValues) => {
    if (cvFiles.length === 0) {
      setCvDataError({
        ...cvDataError,
        state: true,
        code: SystemObj.SEVERITY.WARNING,
        message: SYSTEM_STRINGS.COMPONENTS.JOB_APPLICATION.WARNING.CV,
      });
    } else {
      setSubmittedData(data);
      mutationForm
        .mutateAsync()
        .then((response) => response.json())
        .then((response) => {
          if (response.personioBody.hasOwnProperty(SystemObj.SEVERITY.ERROR)) {
            setShowAlert({
              ...showAlert,
              state: true,
              code: SystemObj.SEVERITY.ERROR,
              message: response.personioBody.error,
            });
          } else if (
            response.personioBody.hasOwnProperty(SystemObj.SEVERITY.SUCCESS)
          ) {
            setShowAlert({
              ...showAlert,
              state: true,
              code: SystemObj.SEVERITY.SUCCESS,
              message: response.personioBody.success,
            });
          }
          resetForm();
          setCvFiles([]);
          setOtherFiles([]);
          setSubmittedData({});
        })
        .catch((e) => {
          console.error('Error:', e);
        });
    }
  };

  let content = null;
  if (isLoading) {
    content = (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  } else if (error) {
    content = (
      <Box display="flex" justifyContent="center">
        <Alert
          severity={SystemObj.SEVERITY.ERROR as any}
          text={SYSTEM_STRINGS.ERROR_MESSAGES.LOADING}
          variant="filled"
        />
      </Box>
    );
  } else {
    const [jobOffer] = data['workzag-jobs']['position'].filter(
      (ele: any) => ele.id[0] === jobId
    );
    content = (
      <>
        <Seo
          title={jobOffer.name[0]}
          // keywords={frontmatter.seoKeywords}
          // description={frontmatter.pageDescription}
          // image={featuredImage}
          // pathname={location.pathname}
        />
        <BlockTop
          headline={'KARRIERE bei CONVENTIC'}
          subheadline={'Deine Bewerbung...'}
          diagonalAlignment={DiagonalObj.DiagonalLeftBelow as any}
          bgImage={BgImageNerd}
          zIndex={8}
          padding={PaddingObj.PaddingStarter}
          color={CvtColorSchema.white}
        />

        <BlockStandard
          diagonalAlignment={DiagonalObj.DiagonalLeftBelow as any}
          bgColor={CvtColorSchema.white}
          zIndex={7}
          padding={PaddingObj.PaddingIdle}
        >
          <Grid
            container
            spacing={3}
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={8}>
              {showAlert && (
                <Box display="flex" justifyContent="center">
                  <Alert
                    severity={showAlert.code as any}
                    text={showAlert.message}
                    variant="filled"
                  />
                </Box>
              )}
            </Grid>
            <Grid item xs={8}>
              <Headline variant={'h2'} text={jobOffer.name} />
              <Headline
                text={`${jobOffer.recruitingCategory}, ${
                  jobOffer.employmentType[0] === 'permanent' ? 'Vollzeit ' : ' '
                }· ${jobOffer.office}`}
                variant={'h3'}
              />
              <JobApplicationForm
                formMetaData={formMetaData}
                control={control}
                getValues={getValues}
                errors={formState.errors}
                handleCvFiles={handleCvFiles}
                cvFiles={cvFiles}
                handleOtherFiles={handleOtherFiles}
                otherFiles={otherFiles}
                handleRejectedCvFiles={handleRejectedCvFiles}
                cvDataError={cvDataError}
                handleRejectedOtherFiles={handleRejectedOtherFiles}
                otherDataError={otherDataError}
              />
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={handleSubmit(onSubmit)}
                text={
                  SYSTEM_STRINGS.COMPONENTS.JOB_APPLICATION.LABEL
                    .SEND_APPLICATION
                }
                bgColor={CvtColorSchema.buttonDarkGray}
                color={CvtColorSchema.white}
                hoverColor={CvtColorSchema.green}
                name={'Standardbutton'}
                type={'button'}
                disabled={false}
              />
            </Grid>
          </Grid>
        </BlockStandard>
      </>
    );
  }

  return <Layout>{content}</Layout>;
};

const Wrapper_ApplicationPage = () => {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <JobApplicationPage />
    </QueryClientProvider>
  );
};

export default Wrapper_ApplicationPage;
