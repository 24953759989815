import React from 'react';
import Dropzone from 'react-dropzone';

/*---CVT---*/
import { CvtColorSchema } from '@conventic-web/internal.global';
import { Alert } from '@conventic-web/internal.basic.alert';

/*---Material---*/
import { Container } from '@mui/material';

/*---Utilities---*/
import SYSTEM_STRINGS from '../../../utility/data/system/systemStrings.yaml';

export const DragAndDropArea = ({
  handleFiles,
  files,
  handleRejectedFiles,
  dataError,
}: {
  handleFiles: any;
  files: any;
  handleRejectedFiles: any;
  dataError: any;
}) => {
  const styles = {
    containerDragAndDrop: {
      backgroundColor: CvtColorSchema.bgGray,
      paddingTop: '1em',
      paddingBottom: '1em',
      cursor: 'pointer',
    },
  } as const;

  const handleOverDragArea = (e: any) => {
    e.preventDefault();
    e.currentTarget.style.border = '4px dashed ' + CvtColorSchema.bgDarkGray;
  };

  const handleLeaveDragArea = (e: any) => {
    e.preventDefault();
    e.currentTarget.style.border = '';
  };

  return (
    <Container
      sx={styles.containerDragAndDrop}
      onDragOver={(e) => handleOverDragArea(e)}
      onDragLeave={(e) => handleLeaveDragArea(e)}
    >
      <Dropzone
        onDrop={(acceptedFiles) => handleFiles(acceptedFiles)}
        onDropRejected={(rejectedFiles) => {
          handleRejectedFiles(rejectedFiles);
        }}
        accept={'.pdf, image/jpeg, image/png'}
        maxSize={1048576 * 2}
        multiple={false}
      >
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()}>
              <input {...getInputProps()} />

              {dataError.state ? (
                <Alert
                  severity={dataError.code as any}
                  text={dataError.message}
                  variant="filled"
                />
              ) : files.length > 0 ? (
                <ul>
                  {files.map((file: any, index: number) => {
                    return (
                      <li key={'cvItem' + index}>
                        <span>{file.name}</span>
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <span>
                  {SYSTEM_STRINGS.COMPONENTS.JOB_APPLICATION.LABEL.DRAG_DROP}
                </span>
              )}
            </div>
          </section>
        )}
      </Dropzone>
    </Container>
  );
};
