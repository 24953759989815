import React from 'react';
import styled from '@emotion/styled';
import { useLocation } from '@reach/router';

/*---Utilities---*/
import { useSnackbarUpdate } from '../../../utility/context/snackbar-ctx';
import SYSTEM_STRINGS from '../../../utility/data/system/systemStrings.yaml';

/*---Global---*/
import { getJobOfferFromPersonio } from '../../../global/functions';

/*---Material---*/
import { Box, CircularProgress } from '@mui/material';

/*---CVT---*/
import {
  CvtColorSchema,
  DeviceSizes,
  CvtBasicTheme,
} from '@conventic-web/internal.global';
import { HtmlContent } from '@conventic-web/internal.basic.html-content';
import { Headline } from '@conventic-web/internal.cvt.data-display.headline';
import { Alert } from '@conventic-web/internal.basic.alert';
import { Hexagon } from '@conventic-web/internal.cvt.data-display.hexagon';

/*---Components---*/
import { LinkComponent } from '../../../components/linkComponent';

/*---Objects---*/
import {
  CssEffectObj,
  SystemObj,
  IconComponents,
} from '../../../global/sharedObjects';

const PersonioApplication = styled.div`
  ${CssEffectObj.skew}
  ${CssEffectObj.hoverEffekt}

  background-color: ${CvtColorSchema.green};
  border: none;
  color: ${CvtColorSchema.white};
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-flex;
  justify-content: center;
  margin: 4px 2px;
  cursor: pointer;
  width: 100%;
`;

const PersonioApplicationUnskew = styled.div`
  ${CssEffectObj.unskew}
`;

const JobOfferSingle = () => {
  const location = useLocation();
  const jobId = location.pathname.split('/').pop();
  const toggleSnackbar = useSnackbarUpdate();
  const { error, isLoading, data } = getJobOfferFromPersonio();
  const styles = {
    containerBtnShare: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '1em 0',
    },
    containerKarriereDescriptionOuter: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    containerKarriereDescription: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '5%',
      marginRight: '5%',
    },
    btnShare: {
      backgroundColor: CvtColorSchema.white,
      border: 'none',
    },
    containerJobOppDescription: {
      width: '60%',
      [`@media(max-width: ${DeviceSizes.laptop})`]: {
        width: '100%',
        marginRight: '0%',
      },
    },
    containerJobOppApplication: {
      width: '60%',
      [`@media (max-width: ${DeviceSizes.laptop})`]: {
        marginLeft: 0,
        width: '100%',
      },
    },
    hexagon: {
      base: {
        color: CvtColorSchema.white,
      },
    },
  } as const;

  const copyToClipboard = (e: any) => {
    e.preventDefault();
    navigator.clipboard.writeText(location.href);
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center">
        <Alert
          severity={SystemObj.SEVERITY.ERROR as any}
          text={SYSTEM_STRINGS.ERROR_MESSAGES.LOADING}
          variant="filled"
        />
      </Box>
    );
  }

  const [jobOffer] = data['workzag-jobs']['position'].filter(
    (ele: any) => ele.id[0] === jobId
  );

  return (
    <>
      <Box component="div" sx={styles.containerBtnShare}>
        <button
          onClick={(e) => {
            copyToClipboard(e);
            toggleSnackbar(SYSTEM_STRINGS.GENERAL.SHARE_LINK);
          }}
          style={{ background: 'none', border: 'none' }}
        >
          <Hexagon
            componentIcon={IconComponents.Share}
            styleComponentIcon={styles.hexagon.base}
            size={4}
            alt={'Share'}
            clickable={true}
            invert={false}
            rotate={false}
            margin={'30%'}
          />
        </button>
      </Box>
      <Box component="div" sx={styles.containerKarriereDescriptionOuter}>
        <Box component="div" sx={styles.containerJobOppDescription}>
          <Box component="div" sx={styles.containerKarriereDescription}>
            <Headline variant={'h2'} text={jobOffer.name} />
            <Headline
              text={`${jobOffer.recruitingCategory}, ${
                jobOffer.employmentType[0] === 'permanent' ? 'Vollzeit ' : ' '
              }· ${jobOffer.office}`}
              variant={'h3'}
            />
            {jobOffer.jobDescriptions[0].jobDescription[0] && (
              <div>
                <b>{jobOffer.jobDescriptions[0].jobDescription[0].name[0]}</b>
                <HtmlContent
                  dangerouslySetInnerHTML={
                    jobOffer.jobDescriptions[0].jobDescription[0].value[0] //task
                  }
                  style={CvtBasicTheme.htmlContent.general}
                />
              </div>
            )}
          </Box>
          <Box component="div" sx={styles.containerKarriereDescription}>
            {jobOffer.jobDescriptions[0].jobDescription[1] && (
              <div>
                <b>{jobOffer.jobDescriptions[0].jobDescription[1].name[0]}</b>
                <HtmlContent
                  dangerouslySetInnerHTML={
                    jobOffer.jobDescriptions[0].jobDescription[1].value[0] //profile
                  }
                  style={CvtBasicTheme.htmlContent.general}
                />
              </div>
            )}
          </Box>
          <Box component="div" sx={styles.containerKarriereDescription}>
            {jobOffer.jobDescriptions[0].jobDescription[2] && (
              <div>
                <b>{jobOffer.jobDescriptions[0].jobDescription[2].name[0]}</b>
                <HtmlContent
                  dangerouslySetInnerHTML={
                    jobOffer.jobDescriptions[0].jobDescription[2].value[0] //offering
                  }
                  style={CvtBasicTheme.htmlContent.general}
                />
              </div>
            )}
          </Box>
        </Box>

        <Box component="div" sx={styles.containerJobOppApplication}>
          <Box component="div" sx={styles.containerKarriereDescription}>
            <LinkComponent
              linkTo={SYSTEM_STRINGS.LINKS.INTERN.APPLICATION + '/' + jobId}
            >
              <PersonioApplication>
                <PersonioApplicationUnskew>
                  Jetzt Bewerben
                </PersonioApplicationUnskew>
              </PersonioApplication>
            </LinkComponent>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default JobOfferSingle;
